<template >
  <div class="wrapper">
    <div class="animated fadeIn">
      <b-row v-if="this.password_expired">
        <b-col sm="12">
          <div>
            <b-alert show variant="warning" dismissible>
              <strong>Password Kamu sudah expired harap update kembali password kamu.</strong>
            </b-alert>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card>
            <div slot="header">
              Update Profile
            </div>
            <b-tabs>
              <div class="loading" v-if="isLoading == true">
                  <div class="sk-three-bounce">
                      <div class="sk-child sk-bounce1"></div>
                      <div class="sk-child sk-bounce2"></div>
                      <div class="sk-child sk-bounce3"></div>
                  </div>
              </div>
              <b-tab title="Update Profile" active>
                <br>
                <b-form @submit.prevent="onUpdate" novalidate action="https://vuejs.org/">
                  <b-form-group id="nameInputGroup1"
                                label="Name"
                                label-for="name">
                    <b-form-input id="name"
                                  type="text"
                                  :class="{ 'is-invalid' : $v.form.name.$errors, 'is-valid' : !$v.form.name.$invalid}"
                                  v-model.lazy.trim="$v.form.name.$model"
                                  :state="chkState('name')"
                                  aria-describedby="nameFeedback"
                                  placeholder="Masukan Nama"
                                  autocomplete='given-name'
                                  autofocus />
                    <b-form-invalid-feedback id="nameFeedback" >
                      <span v-if="!$v.form.name.required">- Nama User tidak boleh kosong!</span>
                      <span v-if="!$v.form.name.isUnique">- Nama User tidak boleh sama dengan data sebelumnya!</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group id="emailInputGroup1"
                                label="Email"
                                label-for="email">
                    <b-form-input id="email"
                                  type="email"
                                  :class="{ 'is-invalid' : $v.form.email.$errors, 'is-valid' : !$v.form.email.$invalid}"
                                  v-model.lazy.trim="$v.form.email.$model"
                                  :state="chkState('email')"
                                  aria-describedby="input1LiveFeedback1"
                                  placeholder="Masukan Emauk"
                                  autocomplete='given-name'
                                  autofocus />
                    <b-form-invalid-feedback id="emailFeedback" >
                      <span v-if="!$v.form.email.required">- Email User tidak boleh kosong!</span>
                      <span v-if="!$v.form.email.email">- Email User tidak valid!</span>
                      <span v-if="!$v.form.email.isUnique">- Email User tidak boleh sama dengan data sebelumnya!</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-button type="submit" variant="primary">
                    Submit
                  </b-button>
                </b-form>
              </b-tab>
              <ChangePassword></ChangePassword>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, email, } from "vuelidate/lib/validators"
import ChangePassword from './ChangePassword'
import { mapGetters } from 'vuex'

export default {
  name: "profile",
  components: {
    ChangePassword
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
      },
      name: '',
      email: '',
      errors: {
        code: '',
        message: '',
        status: '',
        form: {
          name: [],
          email: []
        }
      },
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
    ...mapGetters([
      'password_expired'
    ])
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        isUnique (value) {
          if (value === '') return true

          return new Promise ((resolve, reject) => {
            setTimeout(() => {
              this.$http.post(`profile-name/${value}`, {
                names: this.name
              }).then((success) => {
                if (success.data == "") {
                  resolve(true)
                  return true
                }else{
                  reject(false)
                  return false
                }
              })
              .catch((error) => {
                if (error.response) {
                  this.errors.code = error.response.status;
                  this.errors.status = error.response.data.meta.code;
                  this.errors.headers = error.response.headers;
                }
              })
            }, 350 + Math.random() * 300)
          })
        }
      },
      email: {
        required,
        email,
        isUnique (value) {
          if (value === '') return true

          return new Promise ((resolve, reject) => {
            setTimeout(() => {
              this.$http.post(`profile-email/${value}`, {
                emails: this.email
              }).then((success) => {
                if (success.data == "") {
                  resolve(true)
                  return true
                }else{
                  reject(false)
                  return false
                }
              })
              .catch((error) => {
                if (error.response) {
                  this.errors.code = error.response.status;
                  this.errors.status = error.response.data.meta.code;
                  this.errors.headers = error.response.headers;
                }
              })
            }, 350 + Math.random() * 300)
          })
        }
      }
    }
  },
  created() {
    this.$http.put(`user-information`)
    .then((result) => {
      this.form.name = result.data.name;
      this.name = this.form.name;
      this.form.email = result.data.email;
      this.email = this.form.email;
    }).catch((error) => {
      if (error.response) {
        this.errors.code = error.response.data.meta.code;
        this.errors.status = error.response.status;
        this.errors.headers = error.response.headers;
      }
    })
  },
  methods: {
    onUpdate() {
      // if (this.validate()) {
      //   this.$nextTick(() => {
        this.validate()
        this.isLoading = true
          this.$http.post(`update-account` ,{
              name: this.form.name,
              email:  this.form.email
          }).then(() => {
            this.isLoading = false
            this.$swal.fire(
              'Success!',
              'Profile updated successfully.',
              'success'
            ).then(() =>{
              location.reload()
            })
          }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
            if(this.errors.status == 422) {
              this.$toasted.error('Please fill out the form that must be required')
              this.errors.message = error.response.data.meta.message;
              this.errors.form.name = this.errors.message.name;
              this.errors.form.email = this.errors.message.email;
            }
          }
        })
        // })
      // }
    },
    onReset() {
      // Reset validation
      this.form = Object.assign({})
      this.submitted = false
      this.$nextTick(() => {
        this.$v.$reset()
        this.feedBack = 'secondary'
      })
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  }
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
