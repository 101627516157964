<template >
    <b-tab title="Change Password" >
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <br>
        <b-form v-on:submit.prevent="onSubmit" novalidate action="https://vuejs.org/">
            <b-form-group id="passInputGroup5"
                        label="Old Password"
                        label-for="password">
            <b-form-input id="password"
                            type="password"
                            v-model.trim="$v.form.password.$model"
                            :state="chkState('password')"
                            aria-describedby="passwordFeedback"
                            placeholder="Password"
                            autocomplete='new-password' />
              <b-form-invalid-feedback id="passwordFeedback">
                <span v-if="!$v.form.password.required">- Kata sandi lama  tidak boleh kosong!</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="passInputGroup5"
                        label="New Password"
                        label-for="new-password">
            <b-form-input id="new-password"
                            type="password"
                            v-model.trim="$v.form.new_password.$model"
                            :state="chkState('new_password')"
                            aria-describedby="newPasswordFeedback"
                            placeholder="New Password"
                            autocomplete='new-password' />
                  <b-form-invalid-feedback id="newPasswordFeedback">
                    <span v-if="!$v.form.new_password.required">- Kata sandi baru tidak boleh kosong!</span> <br>
                    <!-- <span v-if="!$v.form.new_password.minLength">- Kata sandi baru minimal 8 karakter!</span> -->
                    <span v-if="!$v.form.password.strongPass">- Kata sandi harus: minimal 8 karakter, mengandung minimal 1 huruf kapital & 1 huruf kecil, mengandung minimal 1 angka, mengandung minimal salah satu simbol berikut [!] , [@] , [#] , [$] ,[%] , [&], [*], [?]</span>
                  </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="confPassInputGroup6"
                        label="Confirm password"
                        label-for="confirm_password">
            <b-form-input id="confirm_password"
                            type="password"
                            v-model.trim="$v.form.confirmPassword.$model"
                            :state="chkState('confirmPassword')"
                            aria-describedby="confirmPassword"
                            placeholder="Confirm password"
                            autocomplete='new-password' />
              <b-form-invalid-feedback id="confirmPassword">
                - Kata sandi baru harus sama!
              </b-form-invalid-feedback>
            </b-form-group>
            <b-row>
              <b-col>
                <h6 class="m-3"><strong>Informasi : </strong></h6>
                <ul class="m-3">
                  <li><strong>Password akan expired setiap 90 hari sekali.</strong></li>
                  <li><strong>Password yang baru dibuat harus berbeda, min 4 perubahan dari password sebelumnya.</strong></li>
                  <li><strong> Kata sandi harus:</strong></li>
                  <ul>
                    <li><strong>minimal 8 karakter</strong></li>
                    <li><strong>mengandung minimal 1 huruf kapital & 1 huruf kecil</strong></li>
                    <li><strong>mengandung minimal 1 angka</strong></li>
                    <li><strong>mengandung minimal salah satu simbol berikut [!] , [@] , [#] , [$] ,[%] , [&], [*], [?]</strong></li>
                  </ul>
                </ul>
              </b-col>
            </b-row>
            <b-button type="submit" variant="primary">
            Submit
            </b-button>
        </b-form>
    </b-tab>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, sameAs, helpers } from "vuelidate/lib/validators"

const strongPass = helpers.regex('strongPass', /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&]).{8,}/)


export default {
  name: "ValidationForms",
  data() {
    return {
      form: {
        password: '',
        new_password: '',
        confirmPassword: ''
      },
      errors: {
        code: '',
        status: '',
        headers: ''
      },
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      password: {
        required
      },
      new_password: {
        required,
        // minLength: minLength(8),
        strongPass
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("new_password")
      }
    }
  },
  methods: {
    onSubmit() {
        this.validate()
        this.isLoading = true
        this.$http.post(`update-password`,{
            currentPassword: this.form.password,
            newPassword: this.form.new_password
        }).then(() => {
            this.isLoading = false
            this.$swal.fire(
              'Success!',
              'Password was changed successfully.',
              'success'
            ).then(() =>{
              location.reload()
            })
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            // this.$toasted.error('Please fill out the form that must be required')
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
            if (this.errors.status === 400 || this.errors.status === 422) {
              this.$swal.fire(
                'Failed!',
                error.response.data.meta.message,
                'error'
              )
            }
          }
        })
    },
    onReset() {
      this.form = Object.assign({})
      this.submitted = false
      this.$nextTick(() => {
        this.$v.$reset()
        this.feedBack = 'secondary'
      })
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  }
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}

.invalid-feedback {
    font-size: 100%;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
